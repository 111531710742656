<template>
  <footer class="footer">
    <div class="container">
      <div class="row flex-md-row">
        <div class="col-12 order-first mb-4 pb-3 mb-lg-0 pb-lg-0">
          <div class="img img--footer">
            <img src="/img/logo-b.svg" alt="">
          </div>
        </div>
        <div class="col-12 col-lg-3 order-3 order-lg-1 mt-lg-5">
          <div v-for="(item, i) in cc.contacts" :key="`foot-contacts-${i}`"
               :class="{'mb-3 mb-md-4': i !== cc.contacts.length - 1}"
               class="footer__contacts"
          >
            <ui-link :data="item" :modifier="`footer-${item.key}`" color="night"
                     class="ts-14 tw-500" />
          </div>
        </div>
        <div class="col-12 col-lg-8 offset-lg-1 col-xl-7 offset-xl-2 order-1 order-lg-2">
          <div class="footer__routes">
            <template v-if="isMob">
              <div class="footer__routes-group">
                <ui-link v-for="(item, n) in cc.links.mob" :key="`foot-route-${n}`"
                         :data="item" modifier="footer" color="gray"
                         :class="{'mb-4': n !== cc.links.mob.length - 1}"
                         class="ts-16 ts-md-14 tw-300"/>
              </div>
            </template>
            <template v-else>
              <div class="footer__routes-group" v-for="(group, i) in cc.links.desk" :key="`foot-route-${i}`">
                <p class="text tc-waikawa ts-18 ts-lg-16 tw-500 mb-3 pb-md-2">{{ group.title }}</p>
                <ui-link v-for="(item, n) in group.items" :key="`foot-route-${n}`"
                         :data="item" modifier="footer" color="gray"
                         :class="{'mb-3': n !== group.items.length - 1}"
                         class="ts-16 ts-lg-14 tw-300" />
                <p v-if="i + 1 === cc.links.desk.length" class="link ts-16 ts-lg-14 tw-300 tc-gray link--footer mt-3" style="cursor: pointer" @click.prevent="showModalComplaint()">
                  {{ cc.links.addons[0].txt }}
                </p>
              </div>
            </template>
          </div>
        </div>
        <div class="col-12 order-2 order-lg-3">
          <hr class="footer__hr">
        </div>
        <div class="col-12 order-4 d-lg-none">
          <hr class="footer__hr">
        </div>
        <div class="col-12 order-last">
          <div class="row">
            <div class="col-12 col-lg-7 col-xl-8 mb-3 mb-lg-0">
              <p class="text tc-gray ts-10 ts-sm-12 tw-300 lh-200">
                {{ cc.disclaimer }}
              </p>
            </div>
            <div class="col-12 col-lg-5 col-xl-4">
              <div class="wrapper d-flex align-items-center justify-content-start justify-content-lg-end">
                <p class="text tc-gray ts-10 ts-sm-12 tw-300 lh-150">
                  {{ cc.copyright[0] }}
                  {{ new Date().getFullYear() }}
                  {{ cc.copyright[1] }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import uiLink from '@/components/UI/Link'
export default {
  components: {
    uiLink
  },
  computed: {
    isMob () {
      return this.$store.state.ww < 768
    },
    cc () {
      return this.$t('footer')
    }
  },
  methods: {
    showModalComplaint () {
      this.$store.state.isModalToggle = true
    }
  }
}
</script>
