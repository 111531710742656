import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
function defaultLanguage () {
  const supportedLanguages = ['cs']
  const browserLang = window.navigator.language.split('-')[0]
  let defaultLang = 'cs'
  if (supportedLanguages.includes(browserLang)) {
    defaultLang = browserLang
  }
  return defaultLang
}

export default new VueI18n({
  locale: defaultLanguage(),
  fallbackLocale: defaultLanguage(),
  messages: loadLocaleMessages()
})
