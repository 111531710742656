<template>
  <a :href="R[link.key]"
     class="link"
     :class="`tc-${color} link--${modifier}`">
    <span>{{ link.text }}</span>
  </a>
</template>
<script>
export default {
  computed: {
    link () {
      return this.$parent.data
    },
    modifier () {
      return this.$parent.modifier
    },
    color () {
      return this.$parent.color
    },
    R () {
      return this.$t('router.default')
    }
  }
}
</script>
