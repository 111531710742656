<template>
  <a :href="link.key" @click.prevent="goToEl(link.key)"
     class="link"
     :class="`tc-${color} link--${modifier}`">
    <span>{{ link.text }}</span>
  </a>
</template>
<script>
export default {
  methods: {
    goToEl (el) {
      console.log(el)
    }
  },
  computed: {
    link () {
      return this.$parent.data
    },
    modifier () {
      return this.$parent.modifier
    },
    color () {
      return this.$parent.color
    }
  }
}
</script>
