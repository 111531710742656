<template>
  <component v-bind:is="data.type"></component>
</template>
<script>
import Default from '@/components/UI/Btn/Default'
import Internal from '@/components/UI/Btn/Internal'
import External from '@/components/UI/Btn/External'
import Scroll from '@/components/UI/Btn/Scroll'
export default {
  components: {
    Default, Internal, External, Scroll
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    type: {
      required: false,
      type: String,
      default: '1'
    },
    color: {
      required: false,
      type: String,
      default: 'yellow'
    }
  }
}
</script>
