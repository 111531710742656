<template>
  <div class="toggle" :class="{'active': isNavToggle}">
    <label class="toggle__cover" @click="$store.commit('toggleNav')"></label>
    <input type="checkbox" class="toggle__checkbox" v-model="isNavToggle" />
    <div class="toggle__button">
      <i></i>
      <i></i>
      <i></i>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isNavToggle () {
      return this.$store.state.isNavToggle
    }
  }
}
</script>
