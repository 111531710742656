<template>
  <div class="modal">
    <div class="modal__wrapper">
      <div class="modal__block">
        <div class="modal__head">
          <p class="text ts-20 tw-500 ta-center">Reklamační formulář</p>
          <div class="modal__close" @click="closeModal()"></div>
        </div>
        <div class="modal__body">
          <form class="form" @submit.prevent="formSubmit()">
            <div class="form__loader" :class="{'active': isLoading}">
              <div class="form__loader-wrapper">
                <div class="form__loader-icon">
                  <img src="/img/loader.gif" alt="Loading" />
                </div>
              </div>
            </div>
            <div class="form__success" :class="{'active': isComplete}">
              <div class="form__success-wrapper">
                <div class="form__success-icon mb-4">
                  <img src="/img/success.svg" alt="Loading" />
                </div>
                <p class="text ts-20 tw-500 ta-center mb-4">Děkujeme za zprávu!</p>
                <div class="btn btn-w-200 btn-t1 btn-night" @click="closeModal()">
                  <span>Zavřít formulář</span>
                </div>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input">
                <label class="form__input-label" for="">Vaše jméno</label>
                <input class="form__input-field" :class="{'invalid': isLength(name, 2) && isDirty}" type="text" name="name" v-model="name" placeholder="Jméno" />
                <span class="form__input-error">This field is required</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input">
                <label class="form__input-label" for="">Váš email</label>
                <input class="form__input-field" :class="{'invalid': !isEmail(email) && isDirty}" type="email" name="email" v-model="email" placeholder="Email" />
                <span class="form__input-error">This field is required</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input">
                <label class="form__input-label" for="">Předmět</label>
                <input class="form__input-field" :class="{'invalid': isLength(subject, 2) && isDirty}" type="text" name="subject" v-model="subject" placeholder="Předmět" />
                <span class="form__input-error">This field is required</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input">
                <label class="form__input-label" for="">Zpráva</label>
                <textarea class="form__input-area" :class="{'invalid': isLength(message, 2) && isDirty}" name="message" v-model="message"></textarea>
                <span class="form__input-error">This field is required</span>
              </div>
            </div>
            <div class="form__group">
              <button class="btn btn-full btn-t1 btn-night" type="submit">
                <span>Odeslat formulář</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    name: '',
    email: '',
    subject: '',
    message: '',
    isDirty: false,
    isLoading: false,
    isComplete: false
  }),
  methods: {
    closeModal () {
      this.resetModal()
      this.$store.state.isModalToggle = false
    },
    resetModal () {
      this.name = ''
      this.email = ''
      this.subject = ''
      this.message = ''
      this.isDirty = false
      this.isLoading = false
      this.isComplete = false
    },
    formSubmit () {
      this.isDirty = true
      if (this.name && this.email && this.subject && this.message) {
        this.isLoading = true
        fetch(`vendor/api/email/sendComplaintEmail.php?email=${this.email}&name=${this.name}&subject=${this.subject}&message=${this.message}`)
          .then((response) => { return response })
          .then((data) => {
            setTimeout(() => {
              this.isLoading = false
              this.isComplete = true
            }, 1000)
          })
      }
    },
    isLength (v, l) {
      return v.length < l
    },
    isEmail (v) {
      const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
      return regex.test(v)
    }
  }
}
</script>
