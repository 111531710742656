<template>
  <router-link tag="a" :to="`/${$i18n.locale}${R[link.key]}`"
               class="link"
               :class="`tc-${color} link--${modifier}`">
    <span>{{ link.text }}</span>
  </router-link>
</template>
<script>
export default {
  computed: {
    link () {
      return this.$parent.data
    },
    color () {
      return this.$parent.color
    },
    modifier () {
      return this.$parent.modifier
    },
    R () {
      return this.$t('router.internal')
    }
  }
}
</script>
