<template>
  <header class="header">
    <div class="header__desk container">
      <div class="header__wrapper">
        <div class="header__row" :class="{'active': isNavToggle}">
          <div class="header__group">
            <router-link :to="`/${$i18n.locale}`" tag="a" class="img img--header" :class="{'active': isNavToggle}">
              <img src="/img/logo-b.svg" alt="MyCoiner" />
              <img src="/img/logo-w.svg" alt="MyCoiner" />
            </router-link>
          </div>
          <div class="header__group">
            <nav class="nav nav--desk">
              <div class="nav__item" v-for="(link, i) in cc.links" :key="`nav-desk-item-${i}`">
                <ui-link :data="link" modifier="header" color="gray" class="tw-400" />
              </div>
            </nav>
          </div>
          <div class="header__group">
            <!--<ui-locale />-->
            <ui-btn :data="cc.btn[0]" type="2" class="btn-w-120 me-2"/>
            <ui-btn :data="cc.btn[1]" type="1" class="btn-w-120 ms-2"/>
          </div>
          <div class="header__toggle">
            <ui-toggle />
          </div>
        </div>
      </div>
    </div>
    <div class="header__mob" :class="{'active': isNavToggle}">
      <div class="header__wrapper">
        <div class="header__row">
          <div class="header__group">
            <!--<ui-locale />-->
          </div>
        </div>
        <div class="header__row">
          <div class="header__group">
            <nav class="nav nav--mob">
              <div class="nav__item" v-for="(link, i) in cc.links" :key="`nav-desk-item-${i}`">
                <ui-link :data="link" modifier="header" color="white" class="ta-center ta-sm-right" />
              </div>
            </nav>
          </div>
        </div>
        <div class="header__row">
          <div class="header__group">
            <ui-btn :data="cc.btn[0]" type="2" class="btn-w-140 mb-3"/>
            <ui-btn :data="cc.btn[1]" type="1" class="btn-w-140"/>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import uiToggle from '@/components/UI/Toggle'
// import uiLocale from '@/components/UI/Locale'
import uiLink from '@/components/UI/Link'
import uiBtn from '@/components/UI/Btn'
export default {
  components: {
    uiToggle, /* uiLocale, */ uiBtn, uiLink
  },
  computed: {
    isNavToggle () {
      return this.$store.state.isNavToggle
    },
    isMobile () {
      return window.innerWidth <= 576
    },
    cc () {
      return this.$t('header')
    }
  }
}
</script>
