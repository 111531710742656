<template>
  <router-link tag="a" :to="`/${$i18n.locale}${R[btn.key]}`"
               class="btn"
               :class="`btn-t${type} btn-${color}`">
    <span>{{ btn.text }}</span>
  </router-link>
</template>
<script>
export default {
  computed: {
    btn () {
      return this.$parent.data
    },
    type () {
      return this.$parent.type
    },
    color () {
      return this.$parent.color
    },
    R () {
      return this.$t('router.internal')
    }
  }
}
</script>
