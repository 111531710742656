<template>
  <div class="wgt wgt-crypto-row">
    <div class="wgt-crypto-row__wrapper" v-if="cryptoActualApi !== null">
      <router-link tag="a" :to="`/${$i18n.locale}/rates`" class="wgt-crypto-row__item" v-for="(item, i) in cryptoList" :key="`cryptel-item-a-${i}`">
        <div class="wrapper d-flex flex-row align-items-center justify-content-center" @click="setSelCrypto(item.key)">
          <div class="wgt-crypto-row__icon me-2 me-xl-3">
            <img :src="`/img/icons/crypto/${item.key}.svg`" alt="">
          </div>
          <p class="text tc-white tw-600 ta-center me-2 me-xl-3">{{ item.key.toUpperCase() }}</p>
          <p class="text tc-white ta-center me-2 me-xl-3">CZK {{ item.price }}</p>
          <p class="text ta-center" v-if="cryptoHistoryApi" :class="[{'tc-up': getCryptoChange(item.key)[0].change.grow}, {'tc-down': !getCryptoChange(item.key)[0].change.grow}]">CZK {{ getCryptoChange(item.key)[0].change.amount }} ({{ getCryptoChange(item.key)[0].change.percent }}%)</p>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    cryptoActualApi () {
      return this.$store.state.cryptoActualApi
    },
    cryptoHistoryApi () {
      return this.$store.state.cryptoHistoryApi
    },
    cryptoList () {
      const tmpArr = []
      for (let i = 0; i < 18; i++) {
        const n = i < 11 ? i : i - 11
        tmpArr.push(this.cryptoActualApi[n])
      }
      return tmpArr
    },
    cryptoActualChange () {
      const hist = this.cryptoHistoryApi
      const actu = this.cryptoActualApi
      const tmpArr = []
      if (hist && actu) {
        actu.forEach((item) => {
          const key = Object.keys(this.cryptoHistoryApi[1][0][item.key])[0]
          const price = +hist[1][0][item.key][key]
          if (price > 0) {
            const change = {
              grow: item.price > price,
              amount: Number((item.price > price ? item.price - price : price - item.price).toFixed(2)),
              percent: Number((item.price > price ? (item.price / price - 1) * 100 : (price / item.price - 1) * 100).toFixed(2))
            }
            tmpArr.push({ key: item.key, price, change })
          }
        })
      }
      return tmpArr
    }
  },
  methods: {
    setSelCrypto (key) {
      this.$store.state.isFetching = true
      this.$store.state.selCrypto = key
      setTimeout(() => {
        this.$store.state.isFetching = false
      }, 1000)
    },
    getCryptoChange (key) {
      const hist = this.cryptoHistoryApi
      const actu = this.cryptoActualApi
      const tmpArr = []
      if (hist && actu) {
        for (let i = 0; i < actu.length; i++) {
          if (actu[i].key === key) {
            const objKey = Object.keys(hist[1][0][key])[0]
            const price = +hist[1][0][key][objKey]
            if (price > 0) {
              const change = {
                grow: actu[i].price > price,
                amount: Number((actu[i].price > price ? actu[i].price - price : price - actu[i].price).toFixed(2)),
                percent: Number((actu[i].price > price ? (actu[i].price / price - 1) * 100 : (price / actu[i].price - 1) * 100).toFixed(2))
              }
              tmpArr.push({ key: actu[i].key, price, change })
            }
            break
          }
        }
      }
      return tmpArr
    }
  }
}
</script>
