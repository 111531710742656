import Vue from 'vue'
import App from './App.vue'
import HighchartsVue from 'highcharts-vue'
import VueMeta from 'vue-meta'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

Vue.config.productionTip = false

Vue.use(HighchartsVue)
Vue.use(VueMeta, { attribute: 'meta-id' })

router.beforeEach((to, from, next) => {
  let language = to.params.lang
  if (!language) {
    language = 'cs'
  }
  i18n.locale = language
  next()
})

new Vue({
  router,
  store,
  i18n,
  created () {
    window.addEventListener('resize', e => {
      this.$store.commit('changeWindowWidth')
    })
  },
  render: h => h(App)
}).$mount('#app')
