import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ww: window.innerWidth,
    isNavToggle: false,
    isCookies: false,
    isModalToggle: false,
    isFetching: false,
    cryptoList: ['btc', 'ltc', 'xrp', 'doge', 'eth', 'xlm', 'etc', 'bnb', 'bch', 'trx', 'ada', 'sol', 'matic', 'algo', 'flow', 'celo', 'egld'],
    cryptoNamespace: {
      btc: 'Bitcoin',
      eth: 'Ethereum',
      bch: 'Bitcoin Cash',
      ltc: 'Litecoin',
      xrp: 'Ripple',
      ada: 'Cardano',
      bnb: 'Binance Coin',
      xlm: 'Stellar',
      trx: 'Tron',
      matic: 'Polygon',
      algo: 'Algorand',
      dash: 'Dash',
      glm: 'Golem',
      xmr: 'Monero',
      zec: 'Zcash',
      doge: 'Dodgecoin',
      czk: 'Česká koruna (CZK)'
    },
    hisRanges: ['1h', '1d', '1m', '3m', '6m', '1y'],
    hisOpt: [
      { type: 'minute', range: 60 },
      { type: 'hour', range: 24 },
      { type: 'day', range: 30 },
      { type: 'day', range: 90 },
      { type: 'day', range: 180 },
      { type: 'day', range: 360 }
    ],
    fetchOpt: {
      type: 'GET',
      redirect: 'follow'
    },
    selCrypto: 'btc',
    selRange: 1,
    cryptoBlockHeight: null,
    cryptoHistoryApi: null,
    cryptoAssetsData: null,
    cryptoHistoryStatic: null,
    cryptoHistoryStore: null,
    cryptoActualApi: null,
    cryptoChangesApi: null,
    cryptoUsdCzkRate: 0
  },
  mutations: {
    toggleNav (state) {
      state.isNavToggle = !state.isNavToggle
    },
    changeWindowWidth (state) {
      state.ww = window.innerWidth
    },
    getCryptoHistory (state) {
      state.isFetching = true
      const crypto = state.cryptoList.join()
      const hisOpt = state.hisOpt[state.selRange]
      const api = new Promise((resolve, reject) => {
        fetch(`https://devclient.mycoiner.cz/api/rates/${crypto}/${hisOpt.type}/${hisOpt.range}`, state.fetchOpt)
          .then((response) => { if (response.ok) return response.json() })
          .then((json) => { resolve(json) })
          .catch((error) => { reject(error) })
      })
      api.then(v => {
        const tmpArr = state.cryptoHistoryApi ? state.cryptoHistoryApi : [[], [], [], [], [], []]
        tmpArr[state.selRange].push(v)
        state.cryptoHistoryApi = tmpArr
        setTimeout(() => {
          state.isFetching = false
        }, 1000)
      })
    },
    getCryptoBlockHeight (state) {
      const api = new Promise((resolve, reject) => {
        fetch(`/vendor/crypto/methods/getBlockHeight.php?asset=${state.selCrypto}`, state.fetchOpt)
          .then((response) => { if (response.ok) return response.json() })
          .then((json) => { resolve(json) })
          .catch((error) => { reject(error) })
      })
      api.then(v => {
        const key = v.Data.symbol
        const height = v.Data.block_height
        if (!state.cryptoBlockHeight) state.cryptoBlockHeight = {}
        if (key && height) {
          const tmpObj = state.cryptoBlockHeight
          tmpObj[key.toLowerCase()] = height
          state.cryptoBlockHeight = tmpObj
        }
      })
    },
    getCryptoHistoryStatic (state, key) {
      const token = 'ysa6CCP6ZVsU7Fev'
      const to = new Date().toISOString().split('.')[0]
      const api = new Promise((resolve, reject) => {
        fetch(`https://payment.imediafile.com/datafeed/getPeriodByAsset/?asset=${key}&token=${token}&period=10DAY&from=2013-10-15T00:00:00&to=${to}`, state.fetchOpt)
          .then((response) => { if (response.ok) return response.json() })
          .then((json) => { resolve(json) })
          .catch((error) => { reject(error) })
      })
      api.then(v => {
        const tmpObj = {}
        tmpObj[key.toLowerCase()] = []
        v.forEach((item) => {
          tmpObj[key.toLowerCase()].push(+item.rate_close.toFixed(0))
        })
        state.cryptoHistoryStatic = tmpObj
      })
    },
    getCryptoActual (state) {
      const api = new Promise((resolve, reject) => {
        fetch('https://devclient.mycoiner.cz/api/assets', state.fetchOpt)
          .then((response) => { if (response.ok) return response.json() })
          .then((json) => { resolve(json) })
          .catch((error) => { reject(error) })
      })
      api.then(v => {
        // console.log(v)
        const rate = v.czk_usd_rate
        const whiteList = ['BTC', 'LTC', 'XRP', 'DOGE', 'ETH', 'XLM', 'ETC', 'BNB', 'BSC', 'BCH', 'TRX', 'ADA', 'SOL', 'MATIC', 'ALGO', 'FLOW', 'CELO', 'EGLD']
        const result = []
        for (const [key, val] of Object.entries(v)) {
          if (whiteList.includes(key) && val.price_usd) {
            const price = +(Number(val.price_usd / rate).toFixed(2))
            // const volume = +val.volume_1hrs_usd.toFixed(0)
            result.push({
              key: key.toLowerCase(),
              name: val.name,
              price
            })
          }
        }
        const tmpObj = {}
        result.forEach(item => {
          const price = item.price
          // const volume = item.volume
          tmpObj[item.key] = { price }
        })
        state.cryptoAssetsData = tmpObj
        state.cryptoUsdCzkRate = rate
        state.cryptoActualApi = result
      })
    },
    getCryptoChanges (state) {
      const apiData = new Promise((resolve, reject) => {
        fetch('https://devclient.mycoiner.cz/api/toplist', state.opt)
          .then((response) => { if (response.ok) return response.json() })
          .then((json) => { resolve(json) })
          .catch((error) => { reject(error) })
      })
      apiData.then(v => { state.cryptoChangesApi = v })
    },
    setCookies (state) {
      const isCookies = window.localStorage.getItem('isCookies') || false
      state.isCookies = isCookies === 'true'
    }
  },
  actions: {},
  modules: {}
})
