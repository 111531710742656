<template>
  <component v-bind:is="data.type"></component>
</template>
<script>
import Default from '@/components/UI/Links/Default'
import Internal from '@/components/UI/Links/Internal'
import External from '@/components/UI/Links/External'
import Scroll from '@/components/UI/Links/Scroll'
export default {
  components: {
    Default, Internal, External, Scroll
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    color: {
      required: false,
      type: String,
      default: 'yellow'
    },
    modifier: {
      required: false,
      type: String,
      default: 'default'
    }
  }
}
</script>
