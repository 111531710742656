<template>
  <a :href="R[link.key]" @click.prevent="goToLink(R[link.key])"
     class="link"
     :class="`tc-${color} link--${modifier}`">
    <span>{{ link.text }}</span>
  </a>
</template>
<script>
export default {
  methods: {
    goToLink (url) {
      window.open(url, '_blank').focus()
    }
  },
  computed: {
    link () {
      return this.$parent.data
    },
    modifier () {
      return this.$parent.modifier
    },
    color () {
      return this.$parent.color
    },
    R () {
      return this.$t('router.external')
    }
  }
}
</script>
