<template>
  <a :href="btn.key" @click.prevent="goToEl(btn.key)"
     class="btn"
     :class="`btn-t${type} btn-${color}`">
    <span>{{ btn.text }}</span>
  </a>
</template>
<script>
export default {
  methods: {
    goToEl (el) {
      console.log(el)
    }
  },
  computed: {
    btn () {
      return this.$parent.data
    },
    type () {
      return this.$parent.type
    },
    color () {
      return this.$parent.color
    }
  }
}
</script>
